<template>
  <div class="page">
    <div class="content">
      <div class="articles">
        <ul class="list webs">
          <li v-for="(item,xh) in webtype" :key="xh" class="li">
            <div class="title">{{item.typename}}</div>
            <div class="webslist">
              <a class="k" :href="it.url" target="_blank" v-for="(it,ind) in getWebs(item.typename)" :key="ind">
                <div class="ico">
                  <template v-if="getIcon(it.url)!=''"><img :src="getIcon(it.url)"></template>
                  <template v-else>{{it.title|first}}</template>
                </div>
                <div class="text" :title="it.title">
                  <div class="textn"> {{it.title}}</div>
                </div>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>


export default {
    name: 'articles',
    data() {
        return {
            webtype: [],
        }
    },
    props: {
        dataList: {
            type: Array,
            default() {
                return []
            }
        }
    },
    created() {
        if (this.$store.state.articles.webType.length>0) {
            this.webtype = this.$store.state.articles.webType
        } else {
            let data = {
                app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
                model_name: 'webtype',
                perpage: 1000,
            }
            this.$store.dispatch('articles/getArticles', data).then((rs) => {
                this.webtype = rs
            })

        }

    },
    methods: {
        getWebs(typename) {
            let fi = this.dataList.filter((el) => {
                return el.typename === typename
            })
            return fi
        },
        getIcon(url) {
            let urlArray = url.split('/')
            let lastUrl = urlArray[0] + '//' + urlArray[2]
            return 'https://favicon.cccyun.cc/'+lastUrl


        }
    },
    computed: {},
    filters: {
        first: (value) => {
            return value.substring(0, 1)
        }

    },
}
</script>

